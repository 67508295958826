import React, { FC } from "react";
import LabelCard from "../../../../models/LabelCard";
import EditLabelForm from "../../EditLabelForm";

interface SingleLabelCardProps {
  blocked: boolean | undefined;
  labelCard: LabelCard;
  rectangleCoordinates: number[];
  index: number;
  deleteLabelCard: (id: any, index: number) => void;
  isEditing: boolean;
  onRectangleEdit: (index: number) => void;
  onSaveLabel: (editedLabelCard: LabelCard, editedCoordinates: number[]) => void;
  onCancelEdit: () => void;
  onShow: (time: number) => void;
  onHover: (title: string) => void;
}

const SingleLabelCard: FC<SingleLabelCardProps> = ({
  labelCard,
  rectangleCoordinates,
  index,
  deleteLabelCard,
  isEditing,
  onRectangleEdit,
  onSaveLabel,
  onCancelEdit,
  onShow,
  blocked,
  onHover
}) => {
  const handleDelete = () => {
    const confirm = window.confirm("Вы уверены?");
    if (!confirm) return;
    deleteLabelCard(labelCard, index);
  };

  const handleEdit = () => {
    onRectangleEdit(index);
  };

  const handleSave = (editedLabelCard: LabelCard, editedCoordinates: number[]) => {
    onSaveLabel(editedLabelCard, editedCoordinates);
  };

  const handleCancel = () => {
    onCancelEdit();
  };
  let timeFrameValue = labelCard.time_frame !== undefined ? String(Math.round(labelCard.time_frame * 100) / 100) : '-'
  if (timeFrameValue !== '-' )
    if (timeFrameValue.split(".")[1])
      for (let i = 0; i < 2 - timeFrameValue.split(".")[1].length; i++) {
        timeFrameValue += "0"
      }
    else
      timeFrameValue += ".00"
  return (
    <div className={"labelCard cursor"}
         onMouseEnter={() => {onHover(labelCard.title)}}
         style={blocked ? {opacity: 0.6, pointerEvents: "none"} : {}}
         onClick={() => {onShow(rectangleCoordinates[4])}} >
      <h2>{labelCard.title}</h2>
      <p className={"time"}><i className={"fal fa-clock"}/>{timeFrameValue}</p>
      {/*<span style={{fontSize: "8px"}}>{rectangleCoordinates.join(",")}с</span>*/}
      <div className="button-container ml-40" style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <button onClick={handleDelete}><i className={"fas fa-times"}></i></button>
      </div>
      {isEditing && (
        <EditLabelForm
          labelCard={labelCard}
          rectangleCoordinates={rectangleCoordinates}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default SingleLabelCard;
