import React from "react";
import {IMaskInput} from 'react-imask';
import './CallbackHunter.scss'

interface ICallBackFormProps {
  onFetch:(url: string, details?: {}) => void;
  onCallMePressed: (x: any) => void;
  inputPhoneRef: React.RefObject<any>
}
interface ICallBackFormState {
  btnDisabled: boolean,
}
export default class CallBackForm extends React.Component<ICallBackFormProps, ICallBackFormState> {
  private optionalInfoInputRef: React.RefObject<any>;
  constructor(props: ICallBackFormProps) {
    super(props);
    this.state = {
      btnDisabled: true,
    }
    this.optionalInfoInputRef = React.createRef<any>()

  }
  focusOnOptionalInfoRef() {
    if(this.optionalInfoInputRef.current !== null) {
      this.optionalInfoInputRef.current.focus()
    }
  }
  async filledPhoneInput(){
    const inputPhoneValue = (document.getElementById('phoneData') as HTMLInputElement).value
    await this.props.onFetch('/api/customers/filled_phone', {cell: inputPhoneValue})
  };

  render() {
    const me = this;
    return (
      <form onSubmit={(e) => {
        e.preventDefault()
      }} action={'https://jsonplaceholder.typicode.com/posts'} method={'POST'} className={"modal-form"}>
        <div className={"form-top"}>
          <a onClick={async () => {await this.props.onFetch('/api/customers/pressed_call')}} href={'tel:+79600886409'} className={"modal-btn btn-callNow"}>
            Позвонить сейчас
            <i className={'fal  fa-phone fa-x modal-svg'} ></i>
          </a>
          {/*<a onClick={ async () => {await this.props.onFetch('/api/customers/pressed_email')}}*/}
          {/*   href={'mailto:info@tatjurinform.ru'} className={"modal-btn btn-email"}>*/}
          {/*  Email*/}
          {/*  <i className={'fal fa-envelope modal-svg'}></i>*/}
          {/*</a>*/}
          <a onClick={async () => {
            await this.props.onFetch('/api/customers/pressed_telegram')
          }} href={'https://t.me/viizual'} target={'_blank'} className={"modal-btn btn-message"}>
            Написать
            <i className={'fab fa-telegram modal-svg'}></i>
          </a>
        </div>
        <p className={"form-title"}>Задайте вопрос, мы с Вами свяжемся</p>
        <div className={"form-mid"}>
          <IMaskInput type={'tel'} id={'phoneData'} className={"modal-input input-phone"}
                      mask={"+7 (000) 000-00-00"}
                      inputRef={this.props.inputPhoneRef}
                      onComplete={async () => {
                        await this.filledPhoneInput()
                        setTimeout(()=>{this.focusOnOptionalInfoRef()},100)
                      }}
                      onAccept={(value, mask) => {
                        const btnCallMe = document.querySelector<HTMLElement>('.btn-callMe');
                        const infoInput = document.querySelector<HTMLElement>('.input-optionalInfo')
                        if (btnCallMe !== null && infoInput !== null) {
                          if (mask.masked.isComplete) {
                            btnCallMe.style.cssText = `
                             opacity: 1
                             `;
                            this.setState({btnDisabled: false});
                            infoInput.style.cssText =
                              `
                              visibility: visible;
                              `
                          } else {
                            btnCallMe.style.cssText = `
                             opacity: 0.5
                             `;
                            this.setState({btnDisabled: true})
                            infoInput.style.cssText =
                              `
                              visibility: hidden
                              `
                          }
                        }
                      }}
                      placeholder={"Контактный номер"}
                      prepare={(appended, masked) => {
                        if (appended === '8' && masked.value === '') {
                          return '';
                        }
                        return appended;
                      }}/>
          <input ref={me.optionalInfoInputRef} id={'optionalInputInfo'} className={"modal-input input-optionalInfo"}
                 placeholder={"Ваш вопрос (опционально)"} type="text"/>
        </div>
        <button onClick={() => {
          const data = {
            phone: me.props.inputPhoneRef.current.value,
            description: me.optionalInfoInputRef.current.value
          }
          me.props.onCallMePressed(data);
        }} className={"modal-btn btn-callMe"} disabled={this.state.btnDisabled}>
          ПОЗВОНИТЕ МНЕ
        </button>
      </form>
    )
  }
}