import React from "react";
import "./styles/projects.scss";
import User from "../../../../../common/models/User";
import {ApiEngine} from "api-engine";
import ModalContainer from "../../../../../../../common/layout/modal/ModalContainer";
import ProjectForm from "./form/ProjectForm";
import ProjectWidget from "./widget/ProjectWidget";
import {Route, Routes} from "react-router-dom";
import {ProjectIndex} from "./index/ProjectIndex";
import ProjectShow from "./show/ProjectShow";

interface ProjectsProps {
  user: User;
  api: ApiEngine;
}



export default class Projects extends React.Component<ProjectsProps, any> {
  mounted: boolean = false;

  render() {
    const me = this;
    return <div className={"projects"}>
      <Routes>
        <Route path={":projectId/*"}
               element={<ProjectShow/>}
        />
        <Route path={"/"} element={
          <ProjectIndex api={me.props.api} user={me.props.user} />
        }/>
      </Routes>
    </div>;
  }
}