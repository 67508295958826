import React from "react";
import {ApiEngine} from "api-engine";
import CircleSpinner from "../../../common/loaders/spinners/cirlcle-spinner/CircleSpinner";
import ApiProvider from "../../api/providers/ApiProvider";
import { ApiContext } from "../../api/contexts/ApiContext";
import RateRow from "./rate_row/RateRow";

interface RatesProps {
  api: ApiEngine
  onClick?: (_rate: any) => void
}

interface RatesState {
  rates: undefined | any[]
}

export default class Rates extends React.Component<RatesProps, RatesState> {
  mount: boolean = false;
  constructor(_props: RatesProps) {
    super(_props);
    this.state = {
      rates: undefined
    }
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    if (this.mount) return;
    this.mount = true;
    this.getData();
  }

  getData() {
    const me = this;
    me.props.api.asyncFetchWithoutQueing("/api/rates/index", {}).then((_res: any) => {
      me.setState({
        rates: _res.rates
      });
    })
  }

  render() {
    const me = this;
    return <div className={"rates-page"} >
      <h1>Тарифы</h1>
      {
        !me.state.rates ? <CircleSpinner/> :
        <>
          { me.state.rates.map((_r, _rIndex) => {
            return <RateRow
              onClick={() => {
                if (me.props.onClick) {
                  me.props.onClick(_r);
                }
              }}
              rate={_r}
              key={`rate-${_rIndex}`}/>
          })}
        </>
    }</div>
  }
}