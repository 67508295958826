import React from "react";
import Projects from "../../common/Projects";
import {ApiEngine} from "api-engine";
import User from "../../../../../../common/models/User";
import "./styles/data_source_index.scss"
import ModalContainer from "../../../../../../../../common/layout/modal/ModalContainer";
import ProjectForm from "../../common/form/ProjectForm";
import DataSourceForm from "../form/DataSourceForm";
import DataSourceWidget from "../widget/DataSourceWidget";
import Breadcrumbs from "../../../../../../../../common/breadcrumbs/Breadcrumbs";

interface DataSourceIndexProps {
  project: any
  api:  ApiEngine
  user: User
}

interface DataSourceIndexState {
  dataSources: any[],
  addingDataSource: boolean
}

export default class DataSourceIndex extends React.Component<DataSourceIndexProps, DataSourceIndexState> {
  mounted = false
  constructor(_props: DataSourceIndexProps) {
    super(_props);
    this.state = {
      dataSources: [],
      addingDataSource: false
    }
    this.getDataSources = this.getDataSources.bind(this);
  }

  componentDidMount() {
    if (this.mounted) return
    this.mounted = true;
    this.getDataSources()
  }

  getDataSources() {
    const me = this;
    const url = `/api/projects/${me.props.project.id}/data_sources/index`;
    me.props.api.asyncFetch(url, {}).then((_res) => {
      if (_res.data_sources) me.setState({dataSources: _res.data_sources})
    })
  }

  render() {
    const me = this;

    return <div className={"data-source-index"}>

      <h3>Источники данных</h3>
      <br/>
      { (0 === me.state.dataSources.length) &&
        <>
          <p>Источники данных еще не подключены</p>
        </>
      }

      <button onClick={(ev) => {me.setState({addingDataSource: true})}}
              className={"mb-15 w-100"}
              ><span className={"mr-10"}>ДОБАВИТЬ ИСТОЧНИК</span><i className={"m-0 fal fa-plus"}/></button>
      { me.state.dataSources.map((_dataSource: any, _dataSourceKey: number) => {
        return <DataSourceWidget project={me.props.project} api={me.props.api} dataSource={_dataSource} key={`data-source-${_dataSourceKey}`} />
      })
      }

      { me.state.addingDataSource && <ModalContainer onBackDropClick={() => {me.setState({addingDataSource: false}, () => {
        me.getDataSources();
      })}}><DataSourceForm callback={() => {me.setState({addingDataSource: false}, () => {
        me.getDataSources();
      })}} project={me.props.project}/></ModalContainer> }
    </div>
  }
}