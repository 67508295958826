import React from "react";
import {Route, Routes} from "react-router-dom";
import { ApiContext } from "../../../../api/contexts/ApiContext";
import Projects from "./projects/common/Projects";

export default class CabinetContent extends React.Component<any, any> {
  render() {
    return <div className={"cabinet-content"}>
      <ApiContext.Consumer>
        {
          ({api, user}) => {
            return <Routes>
              <Route path={""} element={<Projects api={api!!} user={user!!}/>}/>
              <Route path={"/projects/*"} element={<Projects api={api!!} user={user!!}/>}/>
              {/*<Route path={"/cabinet/projects/"} element={<Projects api={api!!} user={user!!}/>}/>*/}
              {/*<Route path={"/profile"} element={<h1>Профиль</h1>}/>*/}
              {/*<Route path={"/vacancies"} element={<h1>Вакансии</h1>}/>*/}
              {/*<Route path={"/educations"} element={<h1>Учебные программы</h1>}/>*/}
              {/*<Route path={"/messages"} element={<h1>Сообщения</h1>}/>*/}
              {/*<Route path={"/settings"} element={<h1>Настройки</h1>}/>*/}
              {/*<Route path={"/help"} element={<h1>Помощь</h1>}/>*/}
              {/*<Route path={"/about"} element={<About/>}/>*/}
            </Routes>
          }
        }
      </ApiContext.Consumer>
    </div>
  }
}