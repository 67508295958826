export default class User {
  lastName?: string;
  firstName?: string;
  middleName?: string;
  email?: string;
  cell?: string;

  constructor(params: any) {
    this.lastName = params.lastName;
    this.firstName = params.firstName;
    this.middleName = params.middleName;
    this.email = params.email;
    this.cell = params.cell;
  }

  name() {
    return `${this.lastName} ${this.firstName}`
  }
}