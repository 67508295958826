import React from "react";
import {ApiEngine} from "api-engine";
import "./styles/learning_model_widget.scss";
import LearningModelStatus from "../models/LearningModelStatus";

interface LearningModelWidgetProps {
  project: any;
  api: ApiEngine
  learningModel: any
}

export default class LearningModelWidget extends React.Component<LearningModelWidgetProps, any> {
  render() {
    const me = this;
    return <div className={"learning-model-widget"}>
      <p className={"title"}>{me.props.learningModel.title}</p>
      <p className={"id"}>{me.props.learningModel.id}</p>
      <div className={"flex w-100 justify-between mt-1"}>
        <p className={"status"}>{LearningModelStatus.getCode(me.props.learningModel.status)}</p>
        <p className={'epochs'}>Эпохи: {me.props.learningModel.epochs}</p>
      </div>
      <p className={"id"}>{me.props.learningModel.id}</p>
      {/*<p>{JSON.stringify(me.props.learningModel)}</p>*/}
    </div>;
  }
}