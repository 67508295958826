import React, {ReactNode, RefObject} from "react";

interface CursorFollowerProps {
  target?: ReactNode;

}

interface CursorFollowerState {
  cursorX: number;
  cursorY: number;
}


export default class CursorFollower extends React.Component<CursorFollowerProps, CursorFollowerState> {

  targetRef: RefObject<HTMLDivElement>;
  prevX: number = -1;
  prevY: number = -1;

  constructor(_props: CursorFollowerProps) {
    super(_props);
    this.targetRef = React.createRef<HTMLDivElement>();
  }

  componentDidMount() {
    let me = this;
    document.addEventListener("mousemove", (_event: MouseEvent) => {
      let el = me.targetRef.current;
      if (!el) return;
      el.style.top = `${_event.y - el.clientHeight / 4}px`;
      el.style.left = `${_event.x - el.clientWidth / 2 - el.scrollTop}px`;
      el.style.width = "40px";
      el.style.height = "40px";
      let hoveredElement = document.querySelectorAll( ":hover" )[5];
      if (hoveredElement) {
        el.style.width = `${hoveredElement.getBoundingClientRect().width}px`;
        el.style.height = `${hoveredElement.getBoundingClientRect().height}px`;
        el.style.top = `${hoveredElement.getBoundingClientRect().y}px`;
        el.style.left = `${hoveredElement.getBoundingClientRect().x}px`;
        console.log();
      }

    })
  }


  render() {
    let me = this;

    return <div style={{pointerEvents: "none"}}>
      <div style={{borderRadius: "10px", border: "4px solid var(--azure_a100)", opacity: 0.4, position: "fixed", transition: "500ms"}} ref={me.targetRef}></div>
    </div>;
  }

}