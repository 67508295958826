import React from "react";
import {ApiEngine} from "api-engine";
import "./style/detections_video_list_widget.scss";
import ModalContainer from "../../../../../../../../common/layout/modal/ModalContainer";
import VideoDetectionsPreview from "../video_detections_preview/common/VideoDetectionsPreview";

interface VideoPreviewWidgetProps {
  api: ApiEngine
  detections: any[]
  className: string
}

interface VideoPreviewWidgetState {
  shownFiles: any[]
  showingVideo: any
}

export default class DetectionsVideoListWidget extends React.Component<VideoPreviewWidgetProps, VideoPreviewWidgetState> {
  constructor(_props: VideoPreviewWidgetProps) {
    super(_props);
    this.state = {
      shownFiles: this.extractFiles(this.props.detections),
      showingVideo: null
    }
  }

  extractFiles(dataset: any[]) {
    console.log(dataset)
    let tally = {} as any;
    for(let detection of dataset) {
      if (tally[detection.data_source_file_id]) tally[detection.data_source_file_id] += 1
      else                    tally[detection.data_source_file_id] = 1
    }
    let files = [] as any[];
    for(let title of Object.keys(tally)) {
      files.push({
        data_source_file_id: title,
        amount: tally[title],
        project_id: dataset[0].project_id,
        children: dataset.filter((x: any) => {return x.data_source_file_id === title})
      });
    }
    return files;
  }

  render() {
    const me = this;
    return <div className={"widget-video-list"}>
      { me.state.shownFiles.map((_file, _fileIndex) => {
        return <div
          onClick={(ev: React.MouseEvent<HTMLDivElement>) => {
            ev.stopPropagation();
            me.setState({
              showingVideo: {
                dataSourceFileId: _file.data_source_file_id,
                detections: _file.children
              }
            })
          }}
          className={"file-name"}
          key={`detection-${me.props.className}-${_fileIndex}`}>
          <div>{_file.data_source_file_id}</div>
          <div>{_file.amount}</div>
        </div>
      })
      }
      {me.state.showingVideo && <ModalContainer
          onBackDropClick={() => {
            me.setState({
              showingVideo: null
            })
          }
          }>
        <VideoDetectionsPreview
            api={me.props.api}
            projectId={me.state.shownFiles[0].project_id}
            dataSourceFileId={me.state.showingVideo.dataSourceFileId}
            detections={me.state.showingVideo.detections}
        />
      </ModalContainer>
      }
    </div>;
  }
}