import * as React from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import { ApiContext } from '../../../../../../../api/contexts/ApiContext';
import Projects from "../Projects";
import ProjectControl from "./project_control/ProjectControl";

export default function ProjectShow() {
  let { projectId } = useParams();

  return <div>
    <ApiContext.Consumer>
      {
        (({api, user}) => {
          return <ProjectControl api={api!!} user={user!!} projectId={projectId} />
        })
      }
    </ApiContext.Consumer>
  </div>
}