import * as React from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import { ApiContext } from '../../../../../../../api/contexts/ApiContext';
import DataSourecControl from "./data_source_control/DataSourecControl";

interface DataSourceShowProps {
  project: any;
}
export default function DataSourceShow(props: DataSourceShowProps) {
  let { dataSourceId } = useParams();
  const project = props.project;
  return <div>
    <ApiContext.Consumer>
      {
        (({api, user}) => {
          return <DataSourecControl
            project={project}
            api={api!!}
            user={user!!}
            dataSourceId={dataSourceId} />
        })
      }
    </ApiContext.Consumer>
  </div>
}