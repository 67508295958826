import React from "react";
import './callback.scss'
import CallBackHunter from "./callback_hunter/CallBackHunter";

export default class Callback extends React.Component<any, any> {
  render() {
    return (
      <div className={"callback"}>
        <CallBackHunter />
      </div>
    )
  }
}