import "./style/header.scss";
import React, {ReactNode} from "react";

interface HeaderProps {
  children: ReactNode[];
  style?: any;
}

interface HeaderState {
}

export default class HeaderWithoutUser extends React.Component<HeaderProps, HeaderState> {
  render() {
    let me = this;
    return <header style={me.props.style}>
      { me.props.children.map((_pos, _posIndex) => {
        return <p key={`header-${_posIndex}`}>{_pos}</p>;
      })
      }
    </header>;
  }
}