import React from "react";
import "./styles/data_source_widget.scss";
import CircleSpinner from "../../../../../../../../common/loaders/spinners/cirlcle-spinner/CircleSpinner";
import {ApiEngine} from "api-engine";

interface DataSourceWidgetProps {
  dataSource: any;
  project: any;
  api: ApiEngine;
}

interface DataSourceWidgetState {
  dataSourceFiles: any[] | null;
}

export default class DataSourceWidget extends React.Component<DataSourceWidgetProps, DataSourceWidgetState> {
  mounted = false

  constructor(_props: DataSourceWidgetProps) {
    super(_props);
    this.state = {
      dataSourceFiles: null
    }
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    if (this.mounted) return
    this.mounted = true
    this.getData();
  }

  getData() {
    const me = this;
    const url = `/api/projects/data_sources/${me.props.dataSource.id}/data_source_files/index`;
    me.props.api.asyncFetch(url, {}).then((_dataSourceFileDto) => {
      if (_dataSourceFileDto.data_source_files) me.setState({dataSourceFiles: _dataSourceFileDto.data_source_files})
      else me.setState({dataSourceFiles: []})
    });
  }

  render() {
    const me = this;
    return <div className={"data-source-widget"} onClick={() => {document.location.href=`/cabinet/projects/${me.props.project.id}/data_sources/${me.props.dataSource.id}`;}}>
      <div className={"flex flex-column"}>
        <p className={"title"}>{me.props.dataSource.title}</p>
        <p className={"description"}>{me.props.dataSource.description}</p>
      </div>
      <div className={"flex flex-row"}>
        <p className={"files-amount"}>Количество файлов</p>
        <p className={"files-amount"}>{me.state.dataSourceFiles ? me.state.dataSourceFiles.length : <CircleSpinner scale={0.2}/>}</p>
      </div>
    </div>
  }
}