import React from "react";
import "./style/detections-index.scss";
import {ApiEngine} from "api-engine";
import CircleSpinner from "../../../../../../../../common/loaders/spinners/cirlcle-spinner/CircleSpinner";
import LearningModelWidget from "../../learning_models/widget/LearningModelWidget";
import DetectionWidget from "../widget/DetectionWidget";
import ModalContainer from "../../../../../../../../common/layout/modal/ModalContainer";
import Rates from "../../../../../../../landing/buy/Rates";

interface DetectionsIndexProps {
  project: any
  api: ApiEngine
  user: any
}

interface DetectionsIndexState {
  detections: null | any[],
  chooseRateModalOpened: boolean
}

export default class DetectionsIndex extends React.Component<DetectionsIndexProps, DetectionsIndexState> {
  mounted = false

  constructor(_props: DetectionsIndexProps) {
    super(_props);
    this.state = {
      detections: null,
      chooseRateModalOpened: false
      // detections: [],
    }
    this.getDetections = this.getDetections.bind(this);
  }

  componentDidMount() {
    if (this.mounted) return
    this.mounted = true;
    this.getDetections();
  }

  getDetections() {
    const me = this;
    const url = `/api/users/projects/${me.props.project.id}/detections/index`;
    me.props.api.asyncFetch(url, {}).then((_res: any) => {
      if (_res.detections) {
        let tally = {} as any;
        for(let detection of _res.detections) {
          if (tally[detection.class_name]) tally[detection.class_name] += 1
          else                    tally[detection.class_name] = 1
        }
        let detections = [] as any[];
        for(let title of Object.keys(tally)) {
          detections.push({
            class_name: title,
            amount: tally[title],
            project_id: _res.detections[0].project_id,
            children: _res.detections.filter((x: any) => {return x.class_name === title})
          });
        }
        me.setState({
          detections: detections.sort((a, b) => {return b.amount - a.amount;})
        }, () => {
          setTimeout(me.getDetections, 10000)
        })

      }
      else me.setState({detections: []}, () => { setTimeout(me.getDetections, 60000) })
    })
  }

  render() {
    const me = this;
    if (null === me.state.detections) {
      return <div className={"ml-10"}>
        <h3>Детекции</h3>
        <br/>
        <CircleSpinner scale={0.2} />
      </div>
    }
    return <div className={"ml-10 detections-index"}>
      <h3>Детекции</h3>
      <br/>

      <button
        className={"w-100 mb-15"} onClick={() => {
        me.props.api.asyncFetchWithoutQueing(`/api/users/projects/${me.props.project.id}/reindex`, {
          method: "GET",
          headers: {
            "Accept": "application/zip"
          }
        }).then((res: any) => {
          // alert(JSON.stringify(res));
        }, async (_error: any) => {
          // console.log("===========");
          // console.log(_error);
          // console.log(new String(await _error.body.toString()));
          me.setState({
            chooseRateModalOpened: true
          })
        });
      }}>ПЕРЕИНДЕКСИРОВАТЬ</button>
      { (0 === me.state.detections.length) &&
          <>
              <p>Ни одной детекции еще не получено</p>
          </>
      }
      <div className={"detections-list"}>
        { me.state.detections.map((_detection: any, _detectionKey: number) => {
          return <DetectionWidget
            project={me.props.project}
            api={me.props.api}
            detection={_detection}
            key={`data-source-${_detectionKey}`} />
          })
        }
      </div>
      { me.state.chooseRateModalOpened &&
        <ModalContainer onBackDropClick={() => {
          me.setState({chooseRateModalOpened: false});
        }}>
            <div className={"w-90"} onClick={(_e) => {
              _e.stopPropagation();
            }}>
              <p>Пожалуйста, выберите тариф для сканирования</p>
              <div className={"w-90"}><Rates onClick={(_rate) => {
                const url = `/api/users/projects/${me.props.project.id}/change_active_rate`;
                me.props.api.asyncFetchWithoutQueing(url, {
                  method: "POST",
                  body: JSON.stringify({
                    rateId: _rate.id
                  })
                }).then((_res) => {
                  me.setState({
                    chooseRateModalOpened: false
                  }, () => {
                    alert("Тариф установлен");
                  })
                })
              }} api={me.props.api} /></div>
            </div>
        </ModalContainer>
      }
    </div>
  }
}