import React from "react";
import "./style/breadcrumbs.scss";

interface BreadcrumbsProps {
  children: React.JSX.Element[]
}

export default class Breadcrumbs extends React.Component<BreadcrumbsProps, any> {
  render() {
    const me = this;
    return <ul className={"breadcrumbs"}>
      {me.props.children.map((_x, _key) => {
        return <li className={_key === me.props.children.length - 1 ? "active" : ''} key={`bread-${_key}`}>{_x}</li>
      })}
    </ul>
  }
}