import React from "react";
import "./style/label-widget.scss";

interface LabelWidgetProps {
  title: string
  amount: number
}

export default class LabelWidget extends React.Component<LabelWidgetProps, any> {
  render() {
    const me = this;
    return <div className={"label-widget"}>
      <p className={"title"}>{me.props.title}</p>
      <p className={"amount"}>Разметок: {me.props.amount}</p>
    </div>;
  }
}