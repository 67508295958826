export default class LearningModelStatus {
    private static dictionary = {
        0: "Ожидание обучения",
        1: "Обучается",
        2: "Завершен",
        3: "Произошла ошибка",
    } as any;
    public static getCode(_code: number): string {
        return this.dictionary[_code];
    }
}