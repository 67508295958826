import React from "react";
import "./styles/project_widget.scss"

interface ProjectWidgetProps {
  project: any;
}

export default class ProjectWidget extends React.Component<ProjectWidgetProps, any> {
  render () {
    const me = this;
    return <div className={"project-widget"} onClick={() => {document.location.href=`/cabinet/projects/${me.props.project.id}`}}>
        <p className={"title"}>{me.props.project.title}</p>
      { me.props.project.description && <p className={"description"}>{me.props.project.description}</p> }
    </div>
  }
}