import React from 'react';
import './CallbackHunter.scss'
import CallBackForm from "./CallBackForm";

interface IModalDialogProps {
}

interface IModalDialogState {
  cell: string,
}

export default class CallBackHunter extends React.Component<IModalDialogProps, IModalDialogState> {
  private phoneInputRef: React.RefObject<any>;
  constructor(props: IModalDialogProps) {
    super(props);
    this.state = {
      cell: '',
    };
    this.openModal = this.openModal.bind(this);
    this.phoneInputRef = React.createRef<any>();
    this.focusOnPhoneRef = this.focusOnPhoneRef.bind(this);
    this._callMe = this._callMe.bind(this);
  };

  async getUserInfo (url: string, details?: {}) {
    try {
      fetch(url, {
        method: 'POST',
        headers: {"Content-Type": "application/json"   },
        body: JSON.stringify({
          deviceData: {
            user_agent: navigator.userAgent,
            width: window.screen.width,
            height: window.screen.height,
          },
          ...details
        }),
      })
    } catch (error) {
      console.error('Ошибка', error)
    }
  };
  focusOnPhoneRef() {
    if(this.phoneInputRef.current !== null) {
      this.phoneInputRef.current.focus()
    }
  }

  async openModal() {
    await this.getUserInfo('/api/customers/opened_callback')
    const modalWindow = document.querySelector<HTMLElement>('.modal');
    const modalBtn = document.querySelector<HTMLElement>('.btn--open-modal');
   // const inputPhone = document.querySelector<HTMLElement>('.input-phone');
    if (modalWindow !== null && modalBtn !== null) {
      modalWindow.style.cssText = `
      opacity: 1;
      visibility: visible;
      `;
      modalBtn.style.display = 'none';
    }
    setTimeout(()=> {
    this.focusOnPhoneRef()
    },100)
  };

  _closeModal = async () => {
    const inputPhoneValue = (document.getElementById('phoneData') as HTMLInputElement).value
    await this.getUserInfo('/api/customers/closed_callback', {cell: inputPhoneValue})
    const modalWindow = document.querySelector<HTMLElement>('.modal');
    const modalBtn = document.querySelector<HTMLElement>('.btn--open-modal')
    if (modalWindow !== null && modalBtn !== null) {
      modalWindow.style.cssText = `
      opacity: 0;
      visibility: hidden;
      `;
      modalBtn.style.display = 'inline-block';
    }

  };

  _callMe(data: any) {
    const inputPhoneValue = (document.getElementById('phoneData') as HTMLInputElement).value;
    const inputAdditionalInfo = (document.getElementById('optionalInputInfo') as HTMLInputElement).value;
    const waitingBlock = document.querySelector<HTMLElement>('.waiting-block');
    const modalForm = document.querySelector<HTMLElement>('.modal-form');
    const modal = document.querySelector<HTMLElement>('.modal')
    // await this.getUserInfo('/api/customers/new', {cell: inputPhoneValue, optionalInfo: inputAdditionalInfo})
    this.setState({cell: inputPhoneValue}, () => {
      if (waitingBlock !== null && modalForm !== null && modal !== null) {
        modalForm.style.display = 'none';
        waitingBlock.style.display = 'flex';
        modal.style.maxWidth = '510px'
      }
      let urlParts = [
        window.location.host,
        window.location.pathname,
        window.location.search,
        window.location.hash,
      ]
      let toSend = {...data, requesting_url: urlParts.join("")};
      toSend["cell"] = toSend["phone"];
      delete toSend["phone"];
      fetch("https://viizual.tech/api/marketing/register_callback_request", {
        method: "POST",
        headers: {"Content-Type": "application/json"   },
        body: JSON.stringify(toSend)
      }).then((res) => {
      });
    });
  };

  _closeWaitingWindow = () => {
    const modal = document.querySelector<HTMLElement>('.modal');
    const modalBtn = document.querySelector<HTMLElement>('.btn--open-modal')
    if (modal !== null && modalBtn !== null) {
      modal.style.display = 'none';
      modalBtn.style.display = 'block';
    }
    document.querySelectorAll('input').forEach((el) => {
      el.value = '';
    })
  };

  render() {
    return (
      <>
        <button onClick={async () => {
          await this.openModal()
        }} className={"btn--open-modal"}>
          <i className={'fab fa-telegram fa-5x'}></i>
        </button>
        <div className={"modal"}>
          <button onClick={async () => {
            await this._closeModal()
          }} className={"btn-modal--close"}>
            <i className={'fal fa-times fa-2x'} ></i>
          </button>
          <CallBackForm onFetch = {this.getUserInfo}
                        onCallMePressed ={this._callMe}
                        inputPhoneRef={this.phoneInputRef}
          />
          <div className={'waiting-block'}>
            <p className={'waiting-block-title'}>В ближайшее время с Вами свяжется наш специалист по номеру
              телефона <div style={{height: "20px"}}></div> <span
                className={'waiting-block-title-phone'}>{this.state.cell}</span></p>
            <div className={'waiting-block-wrapper'}>
              <a onClick={() => {
                this._closeWaitingWindow()
              }} className={"modal-btn btn-callMe"}>
                ЖДУ
              </a>
              <a onClick={async () => {
                await this.getUserInfo('/api/customers/pressed_telegram')
              }} href={'https://t.me/viizual'} target={'_blank'} className={"modal-btn btn-message"}>
                Написать
                <i className={'fab fa-telegram modal-svg'}></i>
              </a>
              <a onClick={async () => {
                await this.getUserInfo('/api/customers/pressed_call')
              }} href={'tel:+79600886409'} className={"modal-btn btn-callNow"}>
                Позвонить&nbsp;сейчас
                <i className={'fal  fa-phone  modal-svg'} ></i>
              </a>
            </div>
          </div>
          <span className={"modal-agreement"}>Отправляя данные, вы соглашаетесь с <a style={{padding: 0, background: "transparent", boxShadow: "none"}} href={"/privacy_policy"} target={"_blank"}>политикой обработки персональных данных</a></span>
        </div>
      </>
    )
  }
}