import React from "react";
import LabelCard from "../../../../models/LabelCard";
import SingleLabelCard from "../common/SingleLabelCard";
import CircleSpinner
  from "../../../../../../../../../../../../../../../../common/loaders/spinners/cirlcle-spinner/CircleSpinner";
import MulticircleSpinner
  from "../../../../../../../../../../../../../../../../common/loaders/spinners/multicircle-spinner/MulticircleSpinner";

interface ListByTimeModeProps {
  blockedLabelsIndexes: any
  deleteLabelCard: (card: any) => void
  labelCards: any[]
  rectangleCoordinates: any[]
  onSaveLabel: (newLabelCard: LabelCard, rectangleCoordinates: number[]) => void
  onLabelClick: (x: any) => void
  onHover: (title: string) => void
}

interface ListByTimeModelState {
  times: number[] | null
  chosenTime: number | null
}

export default class ListByTime extends React.Component<ListByTimeModeProps, ListByTimeModelState> {
  mounted: boolean = false
  constructor(_props: ListByTimeModeProps) {
    super(_props);
    this.state = {
      times: null,
      chosenTime: null
    };

    this.extractTimes = this.extractTimes.bind(this);
  }

  componentDidMount() {
    const me = this;
    if (me.mounted) return;
    me.mounted = true;
    me.extractTimes();
  }

  extractTimes() {
    const me = this;
    let times = [];
    for(let o of me.props.labelCards) {
      let time = o.time_frame;
      if (times.indexOf(time) === -1)
        times.push(time);
    }
    me.setState({times: times});
  }

  render() {
    const me = this;
    if (null === me.state.times) return <MulticircleSpinner/>
    return <>
      { me.state.times.map((_time: number, _timeIndex: number) => {
        return <div key={`time-${_timeIndex}`}>
            <button
              onClick={() => {
                me.setState({chosenTime: (_time === me.state.chosenTime) ? null : _time})
              }}
              style={{cursor: "pointer"}}
              className={"w-100 mb-10 flex flex-row content-center items-center"}>
            <i style={{opacity: 0.4}} className={"fas fa-clock pointer mr-auto ml-0"}/>
            <p>{_time}</p>
          </button>
          { me.props.rectangleCoordinates.map((rectangleCoordinates: any[], index: number) => {
            const labelCard = me.props.labelCards[index];
            if (me.state.chosenTime !== labelCard.time_frame) return <></>;
            if (_time !== labelCard.time_frame) return <></>;
            return (
              <SingleLabelCard
                blocked={me.props.blockedLabelsIndexes[labelCard.id]}
                key={`label-card-in-list-by-time-${index}`}
                deleteLabelCard={me.props.deleteLabelCard}
                labelCard={labelCard}
                rectangleCoordinates={rectangleCoordinates}
                index={index}
                isEditing={false}
                onRectangleEdit={() => {}}
                onSaveLabel={me.props.onSaveLabel}
                onCancelEdit={() => {}}
                onShow={me.props.onLabelClick}
                onHover={(title) => {me.props.onHover(title)}}
              />
            );
          })}
        </div>
      })}

    </>
  }
}