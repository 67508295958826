import React from "react";
import ModalContainer from "../../../../../../../../common/layout/modal/ModalContainer";
import {ApiEngine} from "api-engine";
import User from "../../../../../../common/models/User";
import LearningModelWidget from "../widget/LearningModelWidget";
import "./style/learning_models_index_style.scss";
import CircleSpinner from "../../../../../../../../common/loaders/spinners/cirlcle-spinner/CircleSpinner";
import MulticircleSpinner from "../../../../../../../../common/loaders/spinners/multicircle-spinner/MulticircleSpinner";

interface LearningModelIndexProps {
  project: any
  api:  ApiEngine
  user: User
}

interface LearningModelIndexState {
  learningModels: null | any[],
  trainingDataLearningModel: boolean
}

export default class LearningModelsIndex extends React.Component<LearningModelIndexProps, LearningModelIndexState> {
  mounted = false
  constructor(_props: LearningModelIndexProps) {
    super(_props);
    this.state = {
      learningModels: null,
      trainingDataLearningModel: false
    }
    this.getLearningModels = this.getLearningModels.bind(this);
  }

  componentDidMount() {
    if (this.mounted) return
    this.mounted = true;
    this.getLearningModels();
  }

  getLearningModels() {
    const me = this;
    const url = `/api/users/projects/${me.props.project.id}/learning_models/index`;
    me.props.api.asyncFetch(url, {}).then((_res: any) => {
      if (_res.learningModels) me.setState({learningModels: _res.learningModels}, () => {
        setTimeout(me.getLearningModels, 10000) })
      else me.setState({learningModels: []}, () => { setTimeout(me.getLearningModels, 10000) })
    })
  }

  render() {
    const me = this;
    if (null === me.state.learningModels) {
      return <div className={"ml-10"}>
        <h3>Модели в рамках проекта</h3>
        <br/>
        <CircleSpinner scale={0.2} />
      </div>
    }
    return <div className={"ml-10"}>
      <h3>Модели в рамках проекта</h3>
      <br/>
      { (0 === me.state.learningModels.length) &&
          <>
              <p>Ни одной модели еще не обучено в рамках проекта</p>
          </>
      }
      <button
        style={me.state.trainingDataLearningModel ? {opacity: 0.6, pointerEvents: "none"} : {}}
        className={"w-100 mb-15"} onClick={() => {
        me.setState({
          trainingDataLearningModel: true
        }, () => {
          me.props.api.asyncFetchWithoutQueing(`/api/users/projects/${me.props.project.id}/train`, {
            method: "GET",
            headers: {
              "Accept": "application/zip"
            }
          }).then((res: any) => {
            if (res.error) {
              me.setState({trainingDataLearningModel: false});
            } else {
              me.setState({trainingDataLearningModel: false}, () => {
              });
            }
          }, (_err) => {
            me.setState({trainingDataLearningModel: false});
          });
        });
      }}>ПЕРЕОБУЧИТЬ</button>

      <div className={"learning-models-index"}>


        { me.state.learningModels.map((_learningModel: any, _learningModelKey: number) => {
            return <LearningModelWidget
                          project={me.props.project}
                          api={me.props.api}
                          learningModel={_learningModel}
                          key={`data-source-${_learningModelKey}`} />
          })
        }
      </div>

    </div>
  }
}