import React from "react";

interface RateRowProps {
  rate: any;
  onClick: () => void
}

export default class RateRow extends React.Component<RateRowProps, any> {
  render() {
    const me = this;

    return <div className={"rate"} onClick={() => {
      me.props.onClick()
    }}>
      <div className={"title"}>
        <p>{me.props.rate.title}</p>
      </div>
      <div className={"description"}>{me.props.rate.description}</div>
      <div className={"classifiers-amount"}>
        <p className={"text"}>Кол-во признаков:</p>
        <p className={"value"}>{me.props.rate.min_classifiers_amount}-{me.props.rate.max_classifiers_amount}</p></div>
      <div className={"price"}>
        <p className={"price-itself"}>{me.props.rate.value}</p>
        <span className={"metric"}>коп&nbsp;/&nbsp;минута</span></div>
    </div>;
  }
}