import React from "react";
import "./style/video_detections_preview.scss";
import {ApiEngine} from "api-engine";
import CircleSpinner from "../../../../../../../../../common/loaders/spinners/cirlcle-spinner/CircleSpinner";

interface VideoDetectionsPreviewProps {
  api: ApiEngine
  dataSourceFileId: string
  projectId: string
  detections: any[]
}

interface VideoDetectionsPreviewState {
  loading: boolean
  dataSourceFile: any
  videoTotalLength: number
  currentTime: number
  maxNumberOfDetections: number
}

const N = 1000;
export default class VideoDetectionsPreview extends React.Component<VideoDetectionsPreviewProps, VideoDetectionsPreviewState> {
  mounted = false
  videoRef = React.createRef<HTMLVideoElement>()
  constructor(_props: VideoDetectionsPreviewProps) {
    super(_props);
    this.state = {
      loading: true,
      dataSourceFile: null,
      videoTotalLength: 0,
      currentTime: 0,
      maxNumberOfDetections: 1e9
    };
    this.getData = this.getData.bind(this);
    this.detectDuration = this.detectDuration.bind(this);
  }

  componentDidMount() {
    if (this.mounted) return;
    this.mounted = true
    this.getData();
    console.log(this.props.detections)
  }

  getData() {
    const me = this;
    const url = `/api/users/projects/${me.props.projectId}/data_source_files/${me.props.dataSourceFileId}`;
    me.props.api.asyncFetch(url, {}).then((_res) => {
      me.setState({
        loading: false,
        dataSourceFile: _res,
      }, me.detectDuration)
    })
  }

  detectDuration() {
    const me = this;
    if (me.videoRef.current) {
      if (me.videoRef.current.duration) {
        let max = 1;
        for(let i = 1; i < N; i++ ) {
          const delta = me.videoRef.current.duration / N;
          const lower = (i - 1) * delta;
          const upper = (i + 1) * delta;
          const amnt = me.props.detections.filter((x) => {return lower <= x.start_time && x.start_time <= upper;}).length;
          if (amnt > max) max = amnt;
        }

        me.setState({
          videoTotalLength: me.videoRef.current.duration,
          maxNumberOfDetections: max
        });
        return;
      }
    }
    setTimeout(me.detectDuration, 300);
  }

  render() {
    const me = this;
    return <div className={"video-detections-preview"} onClick={(e) => {
      e.stopPropagation();
    }}>
      {
        me.state.loading ? <CircleSpinner/> :
          <>
            <div className={"video-container"}>
              <video
                ref={me.videoRef}
                src={`${me.props.api.serverUrl}${me.state.dataSourceFile.file_url}`}/>
              {me.state.videoTotalLength > 0 && me.props.detections.filter((x) => {
                return me.state.currentTime >= x.start_time - 0.45 && me.state.currentTime <= x.end_time + 0.45
              }).map((_det, _detIndex) => {
                const c = _det.coordinates;
                let startX =  c[0];
                let endX =  c[2];
                let startY =  c[1];
                let endY =  c[3];
                let w = endX - startX;
                let h = endY - startY;

                return <div
                    className={"detection"}
                    key={`time-is-${me.state.currentTime}-${_detIndex}`}
                    style={{
                      left: `${(startX) * 100}%`,
                      top: `${(startY) * 100}%`,
                      width: `${(w) * 100}%`,
                      height: `${(h) * 100}%`,
                      opacity: `${(me.state.currentTime >= _det.start_time - 0.05 && me.state.currentTime <= _det.end_time + 0.05) ? 1 : 0 }`
                    }}
                  ><div className={"title"}>{_det.class_name}</div></div>
                })
              }
              {me.state.videoTotalLength > 0 && me.props.detections.length > 0 &&
                  <div className={"activation-zone"}>
                    {Array.from(Array(N).keys()).map((_det, _detIndex) => {
                        return <div className={"activation-column"} onMouseEnter={() => {
                          me.setState({
                            currentTime: (_detIndex * me.state.videoTotalLength ) / N
                          }, () => {
                            me.videoRef.current!!.currentTime = (_detIndex * me.state.videoTotalLength ) / N;
                          })
                        } }><div
                          className={"level-of-detections"}
                          style={{
                            height: `calc(40 * ${me.props.detections.filter((x) => {
                              const delta = me.state.videoTotalLength / N;
                              const lower = (_detIndex - 1) * delta;
                              const upper = (_detIndex + 1) * delta;
                              return lower < x.start_time && x.start_time < upper;}).length} / ${me.state.maxNumberOfDetections} * 1px)`
                          }}
                        ></div></div>
                      })
                    }
                  </div>
              }
            </div>
          </>
      }
    </div>;
  }
}