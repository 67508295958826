import React from "react";
import Breadcrumbs from "../../../../../../../../../common/breadcrumbs/Breadcrumbs";
import {ApiEngine} from "api-engine";
import User from "../../../../../../../common/models/User";
import DataSourceFileWidget from "../../data_source_files/widget/DataSourceFileWidget";
import {Route, Routes} from "react-router-dom";
import DataSourceFilesIndex from "../../data_source_files/index/DataSourceFilesIndex";
import MulticircleSpinner
  from "../../../../../../../../../common/loaders/spinners/multicircle-spinner/MulticircleSpinner";
import DataSourceFileLabeling
  from "../../data_source_files/labeling/common/data_source_file_labeling/DataSourceFileLabeling";

interface DataSourecControlProps {
  project: any;
  api: ApiEngine;
  user: User;
  dataSourceId: any;
}

interface DataSourceControlState {
  dataSource: any;
}

export default class DataSourecControl extends React.Component<DataSourecControlProps, DataSourceControlState> {
  mounted = false

  constructor(_props: DataSourecControlProps) {
    super(_props);
    this.state = {
      dataSource: null,
    };
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    if (this.mounted) return;
    this.mounted = true;
    this.getData();
  }

  getData() {
    const me = this;
    const url = `/api/projects/data_sources/${me.props.dataSourceId}/show`;
    me.props.api.asyncFetch(url, {}).then((_dataSource) => {
      me.setState({dataSource: _dataSource}, () => {

      });
    });
  }



  render() {
    const me = this;
    return <div className={"data-source-control"}>
      { me.state.dataSource ?
        <Routes>
          <Route path={"/data_source_files/:dataSourceFileId"} element={<>
            <DataSourceFileLabeling project={me.props.project}
                                    dataSource={me.state.dataSource}/>
          </>} />
          <Route path={"/"} element={
            <>
              <Breadcrumbs>
                <a href={"/cabinet/projects"}>Проекты</a>
                <i className={"fal fa-angle-right"}/>
                <div onClick={() => {document.location.href = `/cabinet/projects/${me.props.project.id}`;}} className={"breadcrumb-project-name"}>
                  <h3>{me.props.project.title}</h3>
                  <p>{me.props.project.description}</p>
                </div>
                <i className={"fal fa-angle-right"}/>
                { me.state.dataSource &&
                    <div className={"breadcrumb-project-name"}>
                        <h3>{me.state.dataSource.title}</h3>
                        <p>{me.state.dataSource.description}</p>
                    </div>
                }
              </Breadcrumbs>
                  <DataSourceFilesIndex
                      project={me.props.project}
                      api={me.props.api}
                      dataSource={me.state.dataSource} />
            </> }/>
        </Routes> : <MulticircleSpinner/> }

    </div>
  }
}