import React from "react";
import "./style/modal_container.scss";

interface ModalContainerProps {
  children: any;
  onBackDropClick: any
}

export default class ModalContainer extends React.Component<ModalContainerProps, any> {
  render() {
    const me = this;
    return <div className={"modal-container"}>
      <div className={"backdrop"}
           onClick={(_ev) => {_ev.stopPropagation(); me.props.onBackDropClick()}}>
        <div className={"content"}>{me.props.children}</div>
      </div>
    </div>
  }
}