import React from "react";
import "../common/article.scss";
export default class AboutUs extends React.Component<any, any> {
  render() {
    return <div className={"article mr-auto ml-auto w-80 mt-20"}>
      <h1>О нас</h1>
      <p>вИИзуал делает машинное зрение доступным всем</p>
      <h2>О компании</h2>
      <p>вИИзуал - это умная видеоаналитика, система поиска по медиаматериалам. Cтартап “вИИзуал” основан в 2023 году. Основной целью является максимальное упрощение использования систем компьютерного зрения для людей.</p>
      <h2>Вкратце о машинном зрении</h2>
      <p>Машинное зрением - технология искусственного интеллекта, использующая алгоритмы и нейросети для извлечения значимой информации из цифровых изображений, видео и иных визуальных потоков ввода.</p>
      <h3>Основные задачи машинного зрения и системы вИИзуал</h3>
      <ul>
        <li><b>Обнаружение объектов</b> – поиск и сопоставление частей изображения с одной из заранее известных категорий</li>
        <li><b>Классификация изображений</b> - сопоставление изображения с одной из заранее известных категорий</li>
        <li><b>Описание одержимого изображения или кадра видео</b></li>
        <li><b>Распознавание текста</b></li>
        <li><b>Сопоставление изображений</b> – поиск совпадающих признаков у набора изображений</li>
        <li><b>Распознавание лиц и людей</b> – сопоставление изображения с одной из заранее известных личностей из заранее заданного набора</li>
        <li><b>Сегментация изображений</b> – разделение изображения на значимые части</li>
        <li><b>Поиск границ</b> – идентификация границ между объектами изображения</li>
        <li><b>Восстановление изображения</b> – реконструкция изображения с улучшенными характеристиками</li>
        <li><b>Трехмерная реконструкция</b> – восстановление трехмерной сцены на основании одного или нескольких снимков</li>
      </ul>
      <h2>Как мы можем помочь</h2>
      <p>В рамках системы вы имеете возможность загрузить свои видеоданные, разметить их. Затем обучить на их основе модель машинного зрения и начать ее использовать.</p>
      <h2>О будущем</h2>
      <p>Cнижение сложности интеграции систем человек-машина и машина-машина открывает возможность радикальной модернизации большинства областей человеческой деятельности.</p>
    </div>;
  }
}