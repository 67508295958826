import React from "react";
import "./style/data_source_file_form.scss";
import {ApiEngine} from "api-engine";

interface DataSourceFileProps {
  dataSource: any;
  project: any;
  callback: any;
  api: ApiEngine;
}

interface DataSourceFileState {
  title: string | null
  description: string | null
  loading: boolean
}

export default class DataSourceFileForm extends React.Component<DataSourceFileProps, DataSourceFileState> {
  private fileRef: React.RefObject<HTMLInputElement>;

  constructor(_props: DataSourceFileProps) {
    super(_props);
    this.state = {
      title: null,
      description: null,
      loading: false
    };
    this.fileRef = React.createRef<HTMLInputElement>();
    this.sendData = this.sendData.bind(this);
  }

  sendData() {
    const me = this;
    let fileData = new FormData();
    fileData.append("file", me.fileRef.current?.files!![0]!!);
    let prevState = {...me.state};
    prevState.loading = true;
    let params = {
      title: me.state.title,
      description: me.state.description,
      data_source_id: me.props.dataSource.id,
      project_id: me.props.project.id
    }
    fileData.append("params", JSON.stringify(params));
    const url = `/api/projects/data_sources/data_source_files/new`;

    me.setState(prevState, () => {
      me.props.api.asyncFetch(url, {
        method: "POST",
        body: fileData
      }).then((_res) => {
        me.props.callback(_res);
      });
    });
  }

  render() {
    const me = this;
    return <div className={"data-source-file-form"} onClick={(_ev) => {_ev.stopPropagation();}}>
      <h3>Новый файл</h3>
      <hr/>
      <input
        onChange={(_ev) => {me.setState({title: _ev.currentTarget.value})}}
        className={"mb-10"}
        placeholder={"Краткое описание файла"}/>
      <input
        onChange={(_ev) => {me.setState({description: _ev.currentTarget.value})}}
        className={"mb-10"}
        placeholder={"Описание"}/>
      <input
            ref={me.fileRef}
            className={"mb-10"}
            type={"file"}
            placeholder={"Файл"}/>
      <button onClick={() => {me.sendData()}} className={`${me.state.loading ? 'disabled' : ''}`}>Создать</button>
    </div>
  }
}