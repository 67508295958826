import React from "react";
import Logo from "../../../common/logo/Logo";
import CursorFollower from "../../../external/cursor-follower/CursorFollower";
import "./landing.scss";

export default class Landing extends React.Component<any, any> {

  render() {
    return <>
      <div className={"v-spacer-5"}></div>
      <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "center"}}>
        <Logo width={window.innerWidth * 0.15}
              color={"rgba(91,132,161,0.51)"}
              style={{marginLeft: "auto", marginRight: "auto"}}/>
      </div>
      <div className={"centered-points"}>
        <h1 style={{opacity: 0.5, color: "var(--color-primary)"}} className={"ml-auto mr-auto"}>вИИзуал</h1>
        <div className={"v-spacer-5"}></div>
        <div className={"points-themselves"} >
          <div className={"point"}>
            <i style={{color: "#5b84a1"}} className={"fal fa-images fa-5x"}></i>
            <div className={"v-spacer-2"}></div>
            <h3 style={{}}>Удобная разметка</h3>
          </div>
          <div className={"point"}>
            <i style={{color: "#5b84a1"}} className={"fal fa-search fa-5x"}></i>
            <div className={"v-spacer-2"}></div>
            <h3 style={{}}>Поиск по медиаматериалам</h3>
          </div>
          <div className={"point"}>
            <i style={{color: "#5b84a1"}} className={"fal fa-eye fa-5x"}></i>
            <div className={"v-spacer-2"}></div>
            <h3 style={{}}>Распознавание в реальном времени</h3>
          </div>
          <div className={"point"}>
            <i style={{color: "#5b84a1"}} className={"fal fa-cloud fa-5x"}></i>
            <div className={"v-spacer-2"}></div>
            <h3 style={{}}>Легкая интеграция</h3>
          </div>
          <div className={"point"}>
            <i style={{color: "#5b84a1"}} className={"fal fa-cloud-download fa-5x"}></i>
            <div className={"v-spacer-2"}></div>
            <h3 style={{}}>Экспортирование нейросетей</h3>
          </div>
        </div>
        <br/>
        <br/>
        <div className={"points-themselves"} >
          <div className={"point"}>
            <i style={{color: "#5b84a1"}} className={"fal fa-code-branch fa-5x"}></i>
            <div className={"v-spacer-2"}></div>
            <h3 style={{}}>Управление предметной областью</h3>
          </div>
          <div className={"point"}>
            <i style={{color: "#5b84a1"}} className={"fal fa-lightbulb fa-5x"}></i>
            <div className={"v-spacer-2"}></div>
            <h3 style={{}}>Новые инсайты</h3>
          </div>
          <div className={"point"}>
            <i style={{color: "#5b84a1"}} className={"fal fa-chart-network fa-5x"}></i>
            <div className={"v-spacer-2"}></div>
            <h3 style={{}}>Новый уровень мониторинга</h3>
          </div>
          <div className={"point"}>
            <i style={{color: "#5b84a1"}} className={"fal fa-code fa-5x"}></i>
            <div className={"v-spacer-2"}></div>
            <h3 style={{}}>API</h3>
          </div>
          <div className={"point"}>
            <i style={{color: "#5b84a1"}} className={"fal fa-network-wired fa-5x"}></i>
            <div className={"v-spacer-2"}></div>
            <h3 style={{}}>Распределенные вычисления</h3>
          </div>
        </div>
      </div>
      <div className={"v-spacer-7"}></div>

    </>
  }
}