import React from "react";
import {ApiContext} from "../../api/contexts/ApiContext";
import ApiProvider from "../../api/providers/ApiProvider";
import Rates from "./Rates";
import "./styles/rates.scss";

export default class RatesPage extends React.Component<any, any> {
  render() {
    return <ApiProvider>
      <ApiContext.Consumer>
        {
          ( {api, user: any}) => {
            return <div className={"article mr-auto ml-auto w-80 mt-20 "}><Rates api={api!!}/></div>
          }
        }</ApiContext.Consumer>
    </ApiProvider>;
  }
}