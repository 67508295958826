import React from "react";
import {ApiEngine} from "api-engine";
import "./style/detection-widget.scss";
import DetectionsVideoListWidget from "../../video_preview/common/DetectionsVideoListWidget";

interface DetectionWidgetProps {
  project: any
  api: ApiEngine
  detection: any
}

interface DetectionWidgetState {
  showingWidget: boolean
}

export default class DetectionWidget extends React.Component<DetectionWidgetProps, DetectionWidgetState> {
  constructor(_props: DetectionWidgetProps) {
    super(_props);
    this.state = {
      showingWidget: false
    }
    this.showWidget = this.showWidget.bind(this);
    this.hideWidget = this.hideWidget.bind(this);
  }

  showWidget() {
    const me = this;
    me.setState({
      showingWidget: true
    });
  }

  hideWidget() {
    const me = this;
    me.setState({
      showingWidget: false
    });
  }

  render() {
    const me = this;
    return <div className={"detection-widget"}
                onClick={() => {me.state.showingWidget ? me.hideWidget() : me.showWidget() }}
    >
      <div className={"flex items-center content-center justify-between w-100"}>
        <h4>{me.props.detection.class_name}</h4>
        <p className={"detections"}>Детекций: {me.props.detection.amount}</p>
      </div>
      {me.state.showingWidget &&
        <DetectionsVideoListWidget
            className={me.props.detection.class_name}
            api={me.props.api}
            detections={me.props.detection.children}/>
      }
    </div>;
  }
}