import "./style/footer.scss";
import React, {ReactNode} from "react";

interface FooterProps {
  children: ReactNode[];
  style?: any;
}

interface FooterState {
}

export default class Footer extends React.Component<FooterProps, FooterState> {
  render() {
    let me = this;
    return <footer style={me.props.style}>
      { me.props.children.map((_pos, _posIndex) => {
        return <p key={`header-${_posIndex}`}>{_pos}</p>;
      })
      }
    </footer>;
  }
}