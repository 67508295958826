import React from "react";
import LabelCard from "../../../../models/LabelCard";
import SingleLabelCard from "../common/SingleLabelCard";

interface AllListViewModeProps {
  blockedLabelsIndexes: any
  deleteLabelCard: (card: any) => void
  labelCards: any[]
  rectangleCoordinates: any[]
  onSaveLabel: (newLabelCard: LabelCard, rectangleCoordinates: number[]) => void
  onLabelClick: (x: any) => void
  onHover: (title: string) => void
}

export default class AllListViewMode extends React.Component<AllListViewModeProps, any> {
  render() {
    const me = this;
    return <div >
      {/*{ me.props.rectangleCoordinates.sort((a, b) => {*/}
      {/*  let indexToSortWith = 4;*/}
      {/*  if (a[indexToSortWith] === b[indexToSortWith]) {*/}
      {/*    indexToSortWith += 1;*/}
      {/*  } else {*/}
      {/*    return a[indexToSortWith] - b[indexToSortWith];*/}
      {/*  }*/}
      {/*  return a[indexToSortWith] < b[indexToSortWith] ? -1 : 1;*/}
      {/*}).map((rectangleCoordinates: any[], index: number) => {*/}
      {/*  const labelCard = me.props.labelCards[index];*/}
      { me.props.labelCards.map((labelCard, labelCardIndex) => {
        return (
          <SingleLabelCard
            blocked={me.props.blockedLabelsIndexes[labelCard.id]}
            key={`label-${labelCard.id}`}
            deleteLabelCard={me.props.deleteLabelCard}
            labelCard={labelCard}
            rectangleCoordinates={[0,0,0,0,labelCard.time_frame, labelCard.title]}
            index={labelCardIndex}
            isEditing={false}
            onRectangleEdit={() => {}}
            onSaveLabel={me.props.onSaveLabel}
            onCancelEdit={() => {}}
            onShow={me.props.onLabelClick}
            onHover={me.props.onHover}
          />
        );
      })}
    </div>
  }
}