import React, { FC, MouseEvent, useEffect, useRef, useState, useCallback } from "react";

interface RectangleProps {
  title: string;
  currentTime: number | null;
  coordinates: number[];
  photoWidth: number;
  photoHeight: number;
  hovered: boolean;
  onCoordinatesChange: (updatedCoordinates: number[]) => void;
}

const Rectangle: FC<RectangleProps> = ({ title, hovered, coordinates, photoWidth, photoHeight, onCoordinatesChange, currentTime }) => {
  // alert(coordinates)
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [dragHandle, setDragHandle] = useState<number | null>(null);

  const rectangleRef = useRef<HTMLDivElement>(null);
  const [x1, y1, x2, y2] = coordinates;

  const handleMouseDown = (event: MouseEvent<HTMLDivElement>, handleIndex: number) => {
    event.stopPropagation();
    setDragHandle(handleIndex);
    setIsResizing(true);
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  const handleMouseMove = useCallback((event: MouseEvent<HTMLDivElement>) => {
    if (!isResizing || dragHandle === null || rectangleRef.current === null) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    const rect = rectangleRef.current.getBoundingClientRect();

    const diffX = event.clientX - rect.left;
    const diffY = event.clientY - rect.top;
    const minWidth = 0;
    const minHeight = 0;

    const updatedCoordinates = [...coordinates];
    switch (dragHandle) {
      case 0:
        updatedCoordinates[0] = Math.min(Math.max(x1 + diffX, 0), x2 - minWidth);
        updatedCoordinates[1] = Math.min(Math.max(y1 + diffY, 0), y2 - minHeight);
        break;
      case 1:
        updatedCoordinates[1] = Math.min(Math.max(y1 + diffY, 0), y2 - minHeight);
        break;
      case 2:
        updatedCoordinates[2] = Math.max(Math.min(x1 + diffX, photoWidth), x1 + minWidth);
        updatedCoordinates[1] = Math.min(Math.max(y1 + diffY, 0), y2 - minHeight);
        break;
      case 3:
        updatedCoordinates[2] = Math.max(Math.min(x1 + diffX, photoWidth), x1 + minWidth);
        break;
      case 4:
        updatedCoordinates[2] = Math.max(Math.min(x1 + diffX, photoWidth), x1 + minWidth);
        updatedCoordinates[3] = Math.max(Math.min(y1 + diffY, photoHeight), y1 + minHeight);
        break;
      case 5:
        updatedCoordinates[3] = Math.max(Math.min(y1 + diffY, photoHeight), y1 + minHeight);
        break;
      case 6:
        updatedCoordinates[0] = Math.min(Math.max(x1 + diffX, 0), x2 - minWidth);
        updatedCoordinates[3] = Math.max(Math.min(y1 + diffY, photoHeight), y1 + minHeight);
        break;
      case 7:
        updatedCoordinates[0] = Math.min(Math.max(x1 + diffX, 0), x2 - minWidth);
        break;
      default:
        break;
    }

    onCoordinatesChange(updatedCoordinates);
  }, [isResizing, dragHandle, x1, x2, y1, y2, coordinates, onCoordinatesChange, photoWidth, photoHeight]);

  useEffect(() => {
    const handleMouseMoveOnWindow = (event: any) => {
      handleMouseMove(event as MouseEvent<HTMLDivElement>);
    };

    const handleMouseUpOnWindow = () => {
      setIsResizing(false);
    };

    if (isResizing) {
      window.addEventListener("mousemove", handleMouseMoveOnWindow);
      window.addEventListener("mouseup", handleMouseUpOnWindow);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMoveOnWindow);
      window.removeEventListener("mouseup", handleMouseUpOnWindow);
    };
  }, [isResizing, handleMouseMove]);

  const handlePositions = [
    [-2, -3], // top-left
    [(x2 - x1) / 2 - 5, -5], // top-middle
    [x2 - x1 - 10, -3], // top-right
    [x2 - x1 - 7, (y2 - y1) / 2 - 5], // right-middle
    [x2 - x1 - 9, y2 - y1 - 9], // bottom-right
    [(x2 - x1) / 2 - 5, y2 - y1 - 7], // bottom-middle
    [-2, y2 - y1 - 9], // bottom-left
    [-5, (y2 - y1) / 2 - 5], // left-middle
  ];

  const getResizeCursor = (handleIndex: number) => {
    const cursorMap = [
      "nw-resize",
      "n-resize",
      "ne-resize",
      "e-resize",
      "se-resize",
      "s-resize",
      "sw-resize",
      "w-resize",
    ];
    return cursorMap[handleIndex];
  };

  if (null === currentTime) return <></>

  return (
    <div
      ref={rectangleRef}
      style={{
        overflow: "hidden",
        transition: "400ms",
        position: "absolute",
        border: `2px solid ${ hovered ? "var(--red)" : "var(--blue)"}`,
        borderRadius: "10px",
        left: `${x1 * 100}%`,
        top: `${y1 * 100}%`,
        width: `${(x2 - x1)*100}%`,
        height: `${(y2 - y1) * 100}%`,
        opacity: (1.3 / (1 + 5 * Math.abs(currentTime - coordinates[4]))),
        pointerEvents: (1.3 / (1 + 5 * Math.abs(currentTime - coordinates[4]))) > 0.5 ? "auto" : "none"
      }}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <p className={"mr-auto w-10 pl-5"} style={{opacity: 0.7, color: "var(--white)", fontSize: "7px", background: "var(--color-primary)", width: "auto"}}>{title}</p>
      {/*<p>{currentTime} / {x1} </p>*/}
      {/*{Array.from({ length: 8 }).map((_, index) => (*/}
      {/*  <div*/}
      {/*    key={`${index}-${hovered}`}*/}
      {/*    style={{*/}
      {/*      position: "absolute",*/}
      {/*      borderRadius: "50%",*/}
      {/*      width: "4px",*/}
      {/*      height: "4px",*/}
      {/*      background: "yellow",*/}
      {/*      cursor: getResizeCursor(index),*/}
      {/*      left: `${handlePositions[index][0]}px`,*/}
      {/*      top: `${handlePositions[index][1]}px`,*/}
      {/*      opacity: (1.3 / (1 + 5 * Math.abs(currentTime - coordinates[4]))),*/}
      {/*      pointerEvents: (1.3 / (1 + 5 * Math.abs(currentTime - coordinates[4]))) > 0.5 ? "auto" : "none"*/}
      {/*    }}*/}
      {/*    onMouseDown={(event) => handleMouseDown(event, index)}*/}
      {/*    onMouseUp={handleMouseUp}*/}
      {/*  />*/}
      {/*))}*/}
    </div>
  );
};

export default Rectangle;
