import React from "react";
import Breadcrumbs from "../../../../../../../../../../../common/breadcrumbs/Breadcrumbs";
import {useParams} from "react-router-dom";
import DataSourceLabelingControl from "./DataSourceLabelingControl";
import { ApiContext } from "../../../../../../../../../../api/contexts/ApiContext";

export default function DataSourceFileLabeling(props: any) {
    const {dataSourceFileId} = useParams();

    return <div className={"data-source-file-labeling"}>
      <ApiContext.Consumer>
        {
          (({api, user}) => {
            return <DataSourceLabelingControl
              project={props.project}
              dataSource={props.dataSource}
              dataSourceFileId={dataSourceFileId}
              api={api!!}
              user={user!!}
            />
        })
        }
      </ApiContext.Consumer>
    </div>
}