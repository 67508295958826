import "./style/header.scss";
import React, {ReactNode} from "react";

interface HeaderProps {
  children: ReactNode[];
  style?: any;
}

interface HeaderState {
}

export default class HeaderForUser extends React.Component<HeaderProps, HeaderState> {
  render() {
    let me = this;
    return <header className={"for-user"} style={me.props.style}>
      { me.props.children.map((_pos, _posIndex) => {
        return <span key={`header-pos-${_posIndex}`}>{_pos}</span>
      })
      }
    </header>;
  }
}